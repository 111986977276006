<template>
  <div class="active-dark">
    <div class="wpp">
      <a :href="$t('whatsapp')" class="whatsapp-button" target="_blank" title="Chat with us on WhatsApp">
        <img src="https://upload.wikimedia.org/wikipedia/commons/6/6b/WhatsApp.svg" alt="WhatsApp">
      </a>
    </div>

    <!-- Start Header Area -->
    <Header>
      <img slot="logo" :src="logo" />
    </Header>
    <!-- End Header Area -->

    <!-- Start Slider Area -->
    <div class="slider-wrapper">
      <SliderOne></SliderOne>
    </div>
    <!-- End Slider Area -->

    <!-- Start About Area -->
    <div id="aboutus" class="about-area ptb--120 bg_color--1">
      <div class="about-wrapper">
        <About>
          <img
            slot="thum-img"
            class="w-100"
            src="../../assets/images/about/introsimonini-13-13.png"
            alt="About Images"
          />
        </About>
      </div>
    </div>
    <!-- End About Area -->

    <!-- Start Service Area -->
    <div id="services" class="service-area ptb--120 bg_color--5">
      <v-container>
        <v-row class="mb--30">
          <v-col cols="12">
            <div class="text-center section-title">
              <span class="subtitle">{{ $t('howCanWeHelp') }}</span>
              <h2 class="heading-title">{{ $t('servicesProvided') }}</h2>
            </div>
          </v-col>
        </v-row>
        <ServiceTwo />
      </v-container>
    </div>
    <!-- End Service Area -->

    <!-- Start Portfolio Area -->
    <div class="portfolio-area ptb--120 bg_color--1">
      <div class="portfolio-wrapper">
        <v-container>
          <v-row>
            <v-col lg="12">
              <div class="text-center section-title">
                <span class="subtitle">{{ $t('ourProjects') }}</span>
                <h2 class="heading-title">
                  {{ $t('someOfOurWork') }}
                </h2>
                <!-- <p class="description">
                  There are many variations of passages of Lorem Ipsum
                  available, <br />
                  but the majority have suffered alteration.
                </p> -->
              </div>
            </v-col>
          </v-row>
          <Portfolio />
        </v-container>
      </div>
    </div>
    <!-- End Portfolio Area -->

    <!-- Start Counterup Area -->
    <!-- <div class="rn-counterup-area ptb--120 bg_color--5">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center section-title">
              <span class="subtitle">CRESCIMENTO</span>
              <h2 class="heading-title">Reconhecimentos</h2>
              <p class="description">
                
              </p>
            </div>
          </v-col>
        </v-row>
        
        <p class="mt--20" align="center">
          <a
            href="https://www.appfutura.com/app-developers/brazil"
            target="_blank"
            class="ml--15 mr--15"
            ><img
              width="200"
              src="https://www.appfutura.com/img/badges/badge-top-app-company-brazil.png"
          /></a>

          <a
            href="https://www.appfutura.com/web-developers/brazil"
            target="_blank"
            class="ml--15 mr--15"
            ><img
              width="200"
              src="https://www.appfutura.com/img/badges/badge-top-web-company-brazil.png"
          /></a>

          <a
            href="https://www.appfutura.com/software-development-companies/brazil"
            target="_blank"
            class="ml--15 mr--15"
            ><img
              width="200"
              src="https://www.appfutura.com/img/badges/badge-top-software-development-company-brazil.png"
          /></a>
        </p>
      </v-container>
    </div> -->
    <!-- End Counterup Area -->

    <!-- Start Brand Area -->
    <div class="rn-brand-area ptb--120 bg_color--5">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="text-center section-title mb--30">
              <span class="subtitle"
                >{{ $t('developedForBigNames') }}</span
              >
              <h2 class="heading-title">{{ $t('clients') }}</h2>
            </div>
          </v-col>
        </v-row>
        <Brand />
      </v-container>
    </div>
    <!-- End Brand Area -->

    <!-- Start Footer Area  -->
    <Footer />
    <!-- End Footer Area  -->
  </div>
</template>

<script>
import Header from "../../components/header/Header";
import SliderOne from "../../components/slider/SliderOne";
import About from "../../components/about/About";
import ServiceTwo from "../../components/service/ServiceTwo";
import Portfolio from "../../components/portfolio/Portfolio";
import CounterOne from "../../components/counter/CounterOne";
import Testimonial from "../../components/testimonial/Testimonial";
import Blog from "../../components/blog/Blog";
import Brand from "../../components/brand/Brand";
import Footer from "../../components/footer/Footer";

export default {
  components: {
    Header,
    SliderOne,
    About,
    ServiceTwo,
    Portfolio,
    CounterOne,
    Testimonial,
    Blog,
    Brand,
    Footer,
  },
  data() {
    return {
      logo: require("../../assets/images/logo/simoninovo-09.png"),
    };
  },
};
</script>
